.App {
  text-align: center;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

#verify-investor-logo {
  display: 'none'
}

.verify-investor-modal-content {
  max-height: 700px;
  overflow-y: auto;
}

.form-sidebar-heading {
  font-size: 16px;
  overflow-wrap: break-word;
  margin-bottom: 35px;
  cursor: pointer;
}

.filepond--credits {
  display: none;
}

.form-dialog {
  transition: opacity 0.5s ease;
}

.form-question {
  font-size: 14px;
  line-height: normal;
  font-weight: 500;
  color: rgb(18, 18, 18);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 0px;
}

.form-grid {
  display: grid;
  gap: 10px 10px;
}

.form-input {
  width: 100%;
  border-width: 1px;
  padding: 6.5px 11px;
  font-size: 14px;
  color: #222222;
}

.form-subtext {
  margin-top: -4px;
  font-size: 14px;
  color: rgb(94, 95, 100);
  margin-bottom: 1em;
}

.loader {
  border-top-color: #3B82F6;
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;
}

@-webkit-keyframes spinner {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spinner {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.form-space {
  padding-bottom: 8px;
}

.verify-investor-modal {
  z-index: 10;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
